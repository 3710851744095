<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M15.48 10.7702L11.69 15.8202H6.07999C5.11999 15.8202 4.63999 14.6602 5.31999 13.9802L10.5 8.80023C11.33 7.97023 12.68 7.97023 13.51 8.80023L15.48 10.7702Z"
      fill="#F9C80E"
    />
    <path
      d="M17.9199 15.8195H11.6899L15.4799 10.7695L18.6899 13.9795C19.3599 14.6595 18.8799 15.8195 17.9199 15.8195Z"
      fill="#F9C80E"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpgradeSvg",
};
</script>

<style scoped></style>

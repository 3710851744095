<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.82002C4.18002 2 2.01001 4.17 2.01001 7.81V16.18C2.01001 19.82 4.18002 21.99 7.82002 21.99H16.19C19.83 21.99 22 19.82 22 16.18V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#CAA8F5"
    />
    <path
      d="M8.18009 16.7698C8.16009 16.7698 8.1301 16.7698 8.1101 16.7698C7.1401 16.6798 6.2301 16.2299 5.5501 15.5099C3.9501 13.8299 3.9501 11.0999 5.5501 9.41986L7.7401 7.11984C8.5201 6.29984 9.5701 5.83984 10.6901 5.83984C11.8101 5.83984 12.8601 6.28984 13.6401 7.11984C15.2401 8.79984 15.2401 11.5298 13.6401 13.2098L12.5501 14.3598C12.2601 14.6598 11.7901 14.6699 11.4901 14.3899C11.1901 14.0999 11.1801 13.6298 11.4601 13.3298L12.5501 12.1798C13.6101 11.0698 13.6101 9.25985 12.5501 8.15985C11.5601 7.11985 9.8201 7.11985 8.8201 8.15985L6.6301 10.4598C5.5701 11.5698 5.5701 13.3799 6.6301 14.4799C7.0601 14.9399 7.64009 15.2198 8.25009 15.2798C8.66009 15.3198 8.96009 15.6899 8.92009 16.0999C8.89009 16.4799 8.56009 16.7698 8.18009 16.7698Z"
      fill="#CAA8F5"
    />
    <path
      d="M13.3102 18.1599C12.1902 18.1599 11.1402 17.7099 10.3602 16.8799C8.76016 15.1999 8.76016 12.4699 10.3602 10.7899L11.4502 9.63991C11.7402 9.33991 12.2102 9.32988 12.5102 9.60988C12.8102 9.89988 12.8201 10.3699 12.5401 10.6699L11.4502 11.8199C10.3902 12.9299 10.3902 14.7399 11.4502 15.8399C12.4402 16.8799 14.1801 16.8899 15.1801 15.8399L17.3702 13.5399C18.4302 12.4299 18.4302 10.6199 17.3702 9.51989C16.9402 9.05989 16.3602 8.7799 15.7502 8.7199C15.3402 8.6799 15.0402 8.30989 15.0802 7.89989C15.1202 7.48989 15.4801 7.17991 15.9001 7.22991C16.8701 7.32991 17.7802 7.76989 18.4602 8.48989C20.0602 10.1699 20.0602 12.8999 18.4602 14.5799L16.2702 16.8799C15.4802 17.7099 14.4302 18.1599 13.3102 18.1599Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "LinkSvg",
};
</script>

<style scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8901 13V14H20.0201C19.2801 14 18.6801 14.6 18.6801 15.35V15.65C18.6801 16.4 18.0801 17 17.3301 17C16.5901 17 15.9901 16.4 15.9901 15.65V15.35C15.9901 14.6 15.3801 14 14.6401 14C13.9001 14 13.3001 14.6 13.3001 15.35V15.65C13.3001 16.4 12.6901 17 11.9501 17C11.2101 17 10.6001 16.4 10.6001 15.65V15.35C10.6001 14.6 10.0001 14 9.26011 14C8.52011 14 7.91011 14.6 7.91011 15.35V15.65C7.91011 16.4 7.31011 17 6.57011 17C5.82011 17 5.22011 16.4 5.22011 15.65V15.33C5.22011 14.59 4.63011 13.99 3.90011 13.98H3.11011V13C3.11011 11.62 4.15011 10.45 5.56011 10.11C5.84011 10.04 6.13011 10 6.44011 10H17.5601C17.8701 10 18.1601 10.04 18.4401 10.11C19.8501 10.45 20.8901 11.62 20.8901 13Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M18.4401 7.17V10.11C18.1601 10.04 17.8701 10 17.5601 10H6.44006C6.13006 10 5.84006 10.04 5.56006 10.11V7.17C5.56006 5.97 6.64006 5 7.98006 5H16.0201C17.3601 5 18.4401 5.97 18.4401 7.17Z"
      fill="#CAA8F5"
    />
    <path
      d="M8.75 3.55039V5.01039H7.98C7.72 5.01039 7.48 5.04039 7.25 5.10039V3.55039C7.25 3.20039 7.59 2.90039 8 2.90039C8.41 2.90039 8.75 3.20039 8.75 3.55039Z"
      fill="#CAA8F5"
    />
    <path
      d="M16.75 3.33008V5.10008C16.52 5.03008 16.28 5.00008 16.02 5.00008H15.25V3.33008C15.25 2.92008 15.59 2.58008 16 2.58008C16.41 2.58008 16.75 2.92008 16.75 3.33008Z"
      fill="#CAA8F5"
    />
    <path
      d="M12.75 2.82V5H11.25V2.82C11.25 2.37 11.59 2 12 2C12.41 2 12.75 2.37 12.75 2.82Z"
      fill="#CAA8F5"
    />
    <path
      opacity="0.4"
      d="M21.25 20.5005H20.89V14.0005H20.02C19.28 14.0005 18.68 14.6005 18.68 15.3505V15.6505C18.68 16.4005 18.08 17.0005 17.33 17.0005C16.59 17.0005 15.99 16.4005 15.99 15.6505V15.3505C15.99 14.6005 15.38 14.0005 14.64 14.0005C13.9 14.0005 13.3 14.6005 13.3 15.3505V15.6505C13.3 16.4005 12.69 17.0005 11.95 17.0005C11.21 17.0005 10.6 16.4005 10.6 15.6505V15.3505C10.6 14.6005 10 14.0005 9.26 14.0005C8.52 14.0005 7.91 14.6005 7.91 15.3505V15.6505C7.91 16.4005 7.31 17.0005 6.57 17.0005C5.82 17.0005 5.22 16.4005 5.22 15.6505V15.3305C5.22 14.5905 4.63 13.9905 3.9 13.9805H3.11V20.5005H2.75C2.34 20.5005 2 20.8405 2 21.2505C2 21.6605 2.34 22.0005 2.75 22.0005H3.11H20.89H21.25C21.66 22.0005 22 21.6605 22 21.2505C22 20.8405 21.66 20.5005 21.25 20.5005Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "BirthSvg",
};
</script>

<style scoped></style>

export default {
  methods: {
    timestampToCustomString(stamp) {
      const shortMonthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date(stamp);
      return `${shortMonthNames[d.getMonth()].substring(
        0,
        3
      )} ${d.getDate()}, ${d.getFullYear()}`;
    },
  },
};

<template>
  <div
    class="char-page char-bg"
    :class="{
      'char-page--bg': isBuy || isSell || sellOnMarket,
      'char-page--padding': isSell,
    }"
  >
    <CryptoShoppieInfo
      :character-image="characterImage"
      :nft-id="nftId"
      :character-id="characterId"
      :character-name="characterNameParsed"
      :creator-name="creatorName"
      :is-community-feed="true"
      :is-in-community="!!breedingAmount"
      @showInCommunity="showInCommunity"
      @removeFromCommunity="removeFromCommunity"
      @goToBreeding="goToBreeding"
      @goToGame="goToGame"
      :is-market="isBuy || sellCharPage || sellOnMarket || isSell"
      :is-in-game="isInGame"
      :is-on-market="isOnMarket"
      :is-csh-owner="isCshOwner"
    />

    <div class="stats-list attr-list">
      <div class="stats-list__head">
        <div class="char-page__title">
          {{ $t("attr-list-title") }}
        </div>
      </div>

      <div class="box">
        <div class="stats-list-chars" v-if="isCshParents">
          <div class="stats-list__char">
            <CharacterModel :image="cshParentImgFirst" />
            <div class="stats-list-chars__title">
              {{ cshParentNameFirst }}
            </div>
          </div>
          <div class="stats-list__char">
            <CharacterModel :image="cshParentImgSecond" />
            <div class="stats-list-chars__title">
              {{ cshParentNameSecond }}
            </div>
          </div>
        </div>

        <ul class="stats-list__items">
          <li v-for="(item, index) in characterPhenotypeRarity" :key="index">
            <StatListItem
              :stat-amount="item.rarity"
              :stat-title="$t(`attr-${item.title}`)"
              :is-attr-list="true"
              :race="item.race"
              :active-check-box="appointParentToPhenotype(item.title)"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="box type-box">
      <div class="type-box__item">
        <div class="type-box__img">
          <img src="@/assets/img/alien.png" alt="" />
        </div>

        <div class="type-box__value">{{ aliensRacePercent }}%</div>

        <div class="type-box__text">
          {{ $t("char-phrase-1") }}
        </div>
      </div>
      <div class="type-box__item">
        <div class="type-box__img">
          <img src="@/assets/img/animal.png" alt="" />
        </div>

        <div class="type-box__value">{{ animalsRacePercent }}%</div>

        <div class="type-box__text">
          {{ $t("char-phrase-2") }}
        </div>
      </div>
      <div class="type-box__item">
        <div class="type-box__img">
          <img src="@/assets/img/mythical.png" alt="" />
        </div>

        <div class="type-box__value">{{ creaturesRacePercent }}%</div>

        <div class="type-box__text">
          {{ $t("char-phrase-3") }}
        </div>
      </div>
    </div>

    <div class="stats-list">
      <div class="stats-list__head">
        <div class="char-page__title">
          {{ $t("stats-list-title") }}
        </div>

        <router-link tag="button" :to="`${id}/upgrade`" class="upgrade-btn">
          <ArrowUpgradeSvg />
          {{ $t("upgrade-btn") }}
        </router-link>
      </div>

      <ul class="stats-list__items box" v-if="characterSkills">
        <li
          v-for="([prop, value], index) in Object.entries(characterSkills)"
          :key="index"
        >
          <StatListItem
            v-if="prop !== '_id'"
            :stat-amount="value"
            :stat-title="$t(`stat-${prop}`)"
          />
        </li>
      </ul>
    </div>

    <div class="about-section">
      <div class="char-page__title">
        {{ $t("about-title") }}
      </div>

      <div class="box">
        <div class="box__wrap">
          <div class="box__img">
            <img src="@/assets/img/user.png" alt="" />
          </div>

          <div class="box__info">
            <div class="box__title">
              {{ $t("char-owner-title") }}
            </div>

            <div class="box__value">{{ creatorName }}</div>
          </div>
        </div>

        <div class="box__wrap">
          <div class="box__img">
            <BirthSvg />
          </div>

          <div class="box__info">
            <div class="box__title">
              {{ $t("owner-birth-title") }}
            </div>
            <div class="box__value" v-if="characterCreatedAt">
              {{ timestampToCustomString(characterCreatedAt) }}
            </div>
          </div>
        </div>

        <div class="box__wrap">
          <div class="box__img">
            <LinkSvg />
          </div>

          <div class="box__info">
            <div class="box__title">
              {{ $t("owner-block-title") }}
            </div>
            <a
              v-if="cshExplorerLink"
              :href="cshExplorerLink"
              target="_blank"
              class="box__value"
            >
              {{ cshExplorerLink.substring(0, 30) }}...
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="char-page__btn" v-if="isBuy">
      <router-link
        :to="`buy/${id}?price=${$route.query.price || 0}&commission=${
          $route.query.commission || 0
        }&itemId=${$route.query.itemId || ''}`"
        tag="button"
        class="game-btn"
        >{{ $t("buy-btn") }}</router-link
      >
    </div>

    <div class="char-page__btn" v-if="isSell && !!nftMarketId">
      <button class="game-btn pink-btn" @click="removeCshFromMarket">
        {{ $t("remove-market-btn") }}
      </button>
    </div>

    <div class="char-page__btn" v-if="isSell && !nftMarketId">
      <router-link :to="`sell/${id}`" tag="button" class="game-btn">{{
        $t("sale-btn")
      }}</router-link>
      <router-link
        :to="`sell-system/${id}`"
        tag="button"
        class="game-btn blue-btn"
        >{{ $t("sell-system-btn") }}</router-link
      >
    </div>
  </div>
</template>

<script>
import loadShoppieByIdMixin from "@/mixins/loadShoppieByIdMixin";
import CryptoShoppieInfo from "../components/shoppie/CryptoShoppieInfo";
import ArrowUpgradeSvg from "../components/icons/ArrowUpgradeSvg";
import StatListItem from "../components/common/StatListItem";
import BirthSvg from "../components/icons/BirthSvg";
import api from "@/api/api";
import TimestampToCustomStringMixin from "@/mixins/date/TimestampToCustomStringMixin";
import CharacterModel from "../components/character/СharacterModel";
import LinkSvg from "../components/icons/LinkSvg";
import SetProcessingMixin from "../mixins/general/SetProcessingMixin";
import successes from "../utils/successes";

export default {
  name: "CryptoshoppiePage",
  mixins: [
    loadShoppieByIdMixin,
    TimestampToCustomStringMixin,
    SetProcessingMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    sellCharPage: {
      default: false,
    },
    sellOnMarket: {
      default: false,
    },
  },
  data() {
    return {
      creator: null,
    };
  },
  computed: {
    isSell() {
      return this.$route.query.sell;
    },
    isBuy() {
      return this.$route.query.price && this.$route.query.commission;
    },
    creatorName() {
      return this.creator ? this.creator.name : "";
    },
  },
  methods: {
    async goToBreeding() {
      await this.$router.push({
        name: "Breeding",
        params: {
          character: this.character,
        },
      });
    },
    async goToGame() {
      await this.$router.push({
        name: "Game",
        params: {
          character: this.character,
        },
      });
    },
    async removeCshFromMarket() {
      try {
        this.setLoading(true);
        await api.removeCshFromMarket(this.nftMarketId);
        this.setSuccess(successes.CSH_REMOVED_FROM_MARKET);
      } catch (e) {
        this.setError({ message: e.response.data.error.message });
      } finally {
        this.setLoading(false);
      }
    },
    appointParentToPhenotype(attr) {
      if (!this.isCshParents) {
        return -1;
      }
      if (typeof this.cshFirstParentPhenotype[attr] === "object") {
        return this.cshFirstParentPhenotype[attr][0] ===
          this.cshPhenotype[attr][0] &&
          this.cshFirstParentPhenotype[attr][1] === this.cshPhenotype[attr][1]
          ? 1
          : this.cshSecondParentPhenotype[attr][0] ===
              this.cshPhenotype[attr][0] &&
            this.cshSecondParentPhenotype[attr][1] ===
              this.cshPhenotype[attr][1]
          ? 2
          : 0;
      }
      return this.cshFirstParentPhenotype[attr] === this.cshPhenotype[attr]
        ? 1
        : this.cshSecondParentPhenotype[attr] === this.cshPhenotype[attr]
        ? 2
        : 0;
    },
    showInCommunity(val) {
      this.breedingAmount = val;
    },
    removeFromCommunity() {
      this.breedingAmount = null;
    },
    async getUserById() {
      try {
        this.creator = await api.getUserById(this.hatchedBy);
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getCryptoshoppiesById(this.id);
    await this.getUserById();
    this.setLoading(false);
  },
  components: {
    LinkSvg,
    CharacterModel,
    BirthSvg,
    StatListItem,
    ArrowUpgradeSvg,
    CryptoShoppieInfo,
  },
};
</script>

<style scoped></style>

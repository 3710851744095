<template>
  <button class="btn-rounded" @click="clicked">
    <slot name="icon" />
  </button>
</template>

<script>
export default {
  name: "RoundedButton",
  props: {},
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>

<template>
  <button
    class="big-btn"
    :class="classes.join(' ')"
    type="button"
    :disabled="disabled"
    @click="click"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    classes: {
      type: Array,
      required: false,
      default: () => [],
    },
    text: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

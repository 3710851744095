<template>
  <div class="stat-item">
    <div class="stat-item__icon">
      <HeartCirceSvg v-if="!isAttrList" />
      <img
        :src="
          require(`@/assets/img/svg/attributes/${race || 'aliens'}/${statTitle
            .toLowerCase()
            .split(' ')
            .join('_')}.png`)
        "
        :alt="statTitle"
        v-if="isAttrList"
        class="stat-item__icon__img"
      />
    </div>

    <div class="stat-item__wrap">
      <div class="stat-item-info">
        <div class="stat-item__title">
          {{ statTitle }}
        </div>

        <div class="stat-item__amount">Level {{ statAmount }}</div>
      </div>

      <div class="stat-item__checks" v-show="isAttrList">
        <div
          class="checkbox"
          :style="{ visibility: activeCheckBox === 1 ? 'visible' : 'hidden' }"
        >
          <input type="checkbox" checked readonly />
          <span class="checkbox__content">
            <span class="checkbox__box"></span>
          </span>
        </div>

        <div
          class="checkbox"
          :style="{ visibility: activeCheckBox === 2 ? 'visible' : 'hidden' }"
        >
          <input type="checkbox" checked readonly />
          <span class="checkbox__content">
            <span class="checkbox__box"></span>
          </span>
        </div>

        <div class="stat-item__mute" v-show="activeCheckBox === 0">
          {{ $t("stat-mutation") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeartCirceSvg from "../icons/HeartCirceSvg";
export default {
  name: "StatListItem",
  components: { HeartCirceSvg },
  props: {
    race: {
      required: false,
      default: () => "aliens",
    },
    statTitle: {
      required: true,
    },
    statAmount: {
      required: true,
    },
    isAttrList: {
      default: false,
    },
    activeCheckBox: {
      type: Number,
      required: false,
      default: -1,
    },
    isMutation: {
      default: false,
    },
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./BirthSvg.vue?vue&type=template&id=249221e2&scoped=true&"
import script from "./BirthSvg.vue?vue&type=script&lang=js&"
export * from "./BirthSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249221e2",
  null
  
)

export default component.exports
<template>
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03 3.46994L6.03 0.469941C5.74 0.179941 5.26 0.179941 4.97 0.469941C4.68 0.759941 4.68 1.23994 4.97 1.52994L6.69 3.24994H1.5C1.09 3.24994 0.75 3.58994 0.75 3.99994C0.75 4.40994 1.09 4.74994 1.5 4.74994H6.69L4.97 6.46994C4.68 6.75994 4.68 7.23994 4.97 7.52994C5.12 7.67994 5.31 7.74994 5.5 7.74994C5.69 7.74994 5.88 7.67994 6.03 7.52994L9.03 4.52994C9.32 4.23994 9.32 3.75994 9.03 3.46994Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRight",
};
</script>

<style scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="#CAA8F5"
    />
    <path
      d="M12.3301 17.0004C12.1501 17.0604 11.8401 17.0604 11.6601 17.0004C10.1001 16.4704 6.6001 14.2404 6.6001 10.4604C6.6001 8.79042 7.9401 7.44043 9.6001 7.44043C10.5801 7.44043 11.4501 7.91042 12.0001 8.65042C12.5401 7.92042 13.4201 7.44043 14.4001 7.44043C16.0601 7.44043 17.4001 8.79042 17.4001 10.4604C17.4001 14.2404 13.9001 16.4704 12.3301 17.0004Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartCirceSvg",
};
</script>

<style scoped></style>
